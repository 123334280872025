<!-- eslint-disable max-len -->
<template>
  <div>
    <v-dialog v-if="subscription" v-model="subscription" persistent max-width="750px">
      <v-card>
        <v-form @submit.prevent="saveSubscription">
          <v-card-title>
            <span class="headline">{{
              subscription.isBatch
              ? "Editar inscrições da Ação de Formação"
              : "Editar inscrição"
            }}
            </span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-layout wrap row class="mx-n-2">
                <v-flex xs6 class="px-2">
                  <v-label>Nota obtida</v-label>
                  <v-text-field type="number" min="0" max="100" step="1" label="Nota" v-model="subscription.Classification">
                    <template v-slot:append>
                      <v-btn fab outline color="secondary" small @click="subscription.Classification = null">
                        N/A
                      </v-btn>
                    </template></v-text-field>
                </v-flex>
                <v-flex xs6 class="px-2">
                  <v-label>Aptidão</v-label>
                  <v-radio-group v-model="subscription.IsApproved" row>
                    <v-radio label="Aprovado" :value="true"></v-radio>
                    <v-radio label="Não aprovado" :value="false"></v-radio>
                  </v-radio-group>
                </v-flex>
                <v-flex xs5 class="px-2">
                  <v-label>Minutos de formação</v-label>
                  <v-text-field type="number" min="0" step="1" label="Minutos" v-model="subscription.Minutes" :disabled="subscription.isBatch"></v-text-field>
                </v-flex>
                <v-flex xs7 class="px-2">
                  <v-label>Data de Realização</v-label>
                  <app-datetime-picker v-model="subscription.DateEnd" title="Data" :disabled="subscription.isBatch"></app-datetime-picker>
                </v-flex>
                <v-flex v-if="courseClass" xs12 class="px-2">
                  <div v-if="subscription.isBatch">
                    <v-alert :value="true" type="info">
                      O tempo de formação será igual ao definido na ação de
                      formação ou o somatório das presenças nas sessões (caso
                      existam).
                    </v-alert>
                  </div>
                  <div v-else>
                    <v-label>Presença confirmada</v-label>
                    <v-radio-group v-model="subscription.CourseClassPresence" row>
                      <v-radio label="Presente" :value="true"></v-radio>
                      <v-radio label="Não compareceu" :value="false"></v-radio>
                    </v-radio-group>
                    <div v-if="courseClassSessions && courseClassSessions.length > 0
                      " class="mb-3">
                      <p>Presença nas Sessões:</p>
                      <ul>
                        <li v-for="(session, index) in courseClassSessions" :key="`session-${index}`">
                          {{ session.Name }}:
                          <strong>{{
                            wasPresent(session) ? "Sim" : "Não"
                          }}</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </v-flex>
                <v-flex xs12 v-if="!subscription.isBatch">
                  <v-label>Ficheiro</v-label>
                  <file-uploader @onProgress="isLoading = true" @onError="
                    isLoading = false;
                  subscription.File = null;
                  " @onSuccess="onFileChange"></file-uploader>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="default" class="text--left" type="button" @click.prevent="subscription = null" :disabled="isLoading">Cancelar</v-btn>
            <v-btn :loading="isLoading" color="accent" type="submit" class="ml-auto">Guardar</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <div class="k-grid k-grid-toolbar">
      <span v-if="!user" class="mr-2">
        <app-form-modal :title-dialog="'Adicionar Inscrições'" :formValid="!$v.SingleInstance.$error && SingleInstance.Code.length > 1
          " :confirmAction="AddSingle" :cancelAction="onCancelAction" :classButton="`mr-2`" titleButton="Inscrever por Códigos">
          <p>Insira por Códigos</p>
          <v-textarea v-model="SingleInstance.Code" :error="$v.SingleInstance.Code.$error" @input="$v.SingleInstance.Code.$touch()" label="Introduza os Códigos por linha" required></v-textarea>
        </app-form-modal>
        <app-subscriptions-batch :course="course" :courseClass="courseClass" @inscriptionsAdded="reload"></app-subscriptions-batch>
      </span>
      <span v-else class="mr-2">
        <app-form-modal :title-dialog="'Adicionar Inscrições'" :formValid="userInscription.IdCourse > 0" :confirmAction="AddUserSubscription" :cancelAction="onCancelAction" titleButton="Inscrever em Formação">
          <p>Escolha o Formação</p>
          <app-courses-selector v-model="userInscription.IdCourse"></app-courses-selector>
        </app-form-modal>
      </span>
      <span v-if="courseClass">
        <v-btn color="accent" @click.prevent="openEditInscriptionModal()" outline>
          Editar Inscrições
        </v-btn>
      </span>
    </div>

    <Grid :style="{ height: 'auto' }" :data-items="Items" :columns="Columns" :pageable="true" :skip="skip" :take="take" :sortable="true" :sort="grid.sort" @sortchange="sortChange" :total="grid.pagging.total" @pagechange="pageChange" @edit="edit" @remove="remove" :filterable="true" :filter="grid.filter" @filterchange="filterChange">
      <template slot="statusCell" slot-scope="{ props }">
        <td :class="props.className">
          <v-chip :color="getStatusColor(props.dataItem)" text-color="white">{{
            getStatusName(props.dataItem)
          }}</v-chip>
        </td>
      </template>
      <template slot="certificationCell" slot-scope="{ props }">
        <td :class="props.className">
          <div v-if="props.dataItem.IsApproved && props.dataItem.Classification >= 0 && props.dataItem.HasTrainerAvaliation && props.dataItem.Course.TypeId == 2">
            <a :href="getCertificationUrl(props.dataItem)" class="btn btn-outline-primary ml-4" target="_blank">
              <i class="icon-catalog-challenge"></i>
              <span>certificado</span>
            </a>
          </div>
          <div v-else-if="props.dataItem.IsApproved && props.dataItem.Classification >= 0 && props.dataItem.Course.TypeId !== 2">
            <a :href="getCertificationUrl(props.dataItem)" class="btn btn-outline-primary ml-4" target="_blank">
              <i class="icon-catalog-challenge"></i>
              <span>certificado</span>
            </a>
          </div>
        </td>
      </template>
      <template slot="courseCell" slot-scope="{ props }">
        <td :class="props.className">
          <router-link :to="`/catalog/courses/${props.dataItem.IdCourse}`">
            {{ props.dataItem.Course[props.field.split(".")[1]] }}
          </router-link>
        </td>
      </template>
      <template slot="userCell" slot-scope="{ props }">
        <td :class="props.className" v-if="$store.state.User.IdUserRole > 3">
          <router-link :to="`/users/${props.dataItem.IdUser}`">
            {{ props.dataItem.User[props.field.split(".")[1]] }}
          </router-link>
        </td>
        <td :class="props.className" v-else>
          {{ props.dataItem.User[props.field.split(".")[1]] }}
        </td>
      </template>
      <template slot="UserLocationCell" slot-scope="{ props }">
        <td>
          {{
            props.dataItem.User.UserPositions.length > 0
            ? props.dataItem.User.UserPositions[0].Location
            : "N/A"
          }}
        </td>
      </template>
      <template slot="ClassificationCell" slot-scope="{ props }">
        <td>
          {{
            props.dataItem.Classification > 0
            ? props.dataItem.Classification
            : "N/A"
          }}
        </td>
      </template>
      <GridNoRecords>Não existem inscrições</GridNoRecords>
    </Grid>
  </div>
</template>

<script>
/* eslint-disable */
import * as kendo from "@progress/kendo-ui";
import { required, minLength } from "vuelidate/lib/validators";
import FormModalComponent from "@/components/modals/form-modal";
import DateTimePickerComponent from "@/components/forms/date-time-picker";
import SubscriptionsBatchComponent from "@/components/grid/subscriptions.batch";
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import subscriptionsService from "@/services/api/userCourseSubscriptionsService";
import courseSessionsService from "@/services/api/courseSessionsService";
import DeleteCommandCell from "@/components/grid/delete-command.js";
import EditCommandCell from "@/components/grid/edit-command.js";
import GridHelper from "@/utilities/grid";
import CoursesSelector from "@/components/selectors/courses";
import StringFilterCell from "@/components/grid/string-filter.js";
import FileAsyncUploaderComponent from "@/components/forms/uploaders/file";
// import { orderBy } from "@progress/kendo-data-query";
import { API_URL } from "@/settings";
export default {
  components: {
    "app-courses-selector": CoursesSelector,
    "app-form-modal": FormModalComponent,
    "app-datetime-picker": DateTimePickerComponent,
    "app-subscriptions-batch": SubscriptionsBatchComponent,
    "file-uploader": FileAsyncUploaderComponent,
  },
  props: ["user", "courseClass", "course"],
  data () {
    return {
      grid: {
        sort: [],
        filter: { logic: "and", filters: [] },
        pagging: {
          pageId: 1,
          pageSize: 10,
          total: 0,
        },
      },
      isLoading: false,
      courseClassSessions: [],
      Items: [],
      subscriptionOriginal: null,
      subscription: null,
      userInscription: {
        IdCourse: 0,
      },
      SingleInstance: { Code: "" },
      MassInstance: { Locations: [], Functions: [], CostCenters: [] },
    };
  },
  validations: {
    SingleInstance: {
      Code: {
        required,
        minLength: minLength(3),
      },
    },
  },
  computed: {
    isMassInstanceValid () {
      return (
        this.MassInstance.Locations.length > 0 ||
        this.MassInstance.Functions.length > 0 ||
        this.MassInstance.CostCenters.length > 0
      );
    },
    skip () {
      return (this.grid.pagging.pageId - 1) * this.grid.pagging.pageSize;
    },
    take () {
      return this.grid.pagging.pageSize;
    },

    Columns () {
      let cols = [
        {
          field: "CurrentCourseModule",
          title: "Módulo Corrente",
          filterable: false,
          sortable: false
        },
        { field: "Minutes", title: "Minutos", filterable: false, sortable: false },
        {
          field: "Classification",
          title: "Nota",
          filterable: false,
          cell: "ClassificationCell",
        },
        // {
        //   field: "Local",
        //   title: "Local",
        //   filterable: false,
        //   sortable: false,
        //   cell: "UserLocationCell"
        // },
        {
          field: "DateEnd",
          title: "Data de conclusão",
          filterable: false,
          sortable: true,
        },
        {
          field: "IsCompleted",
          title: "Estado",
          cell: "statusCell",
          filterable: false,
          sortable: false,
        },
        {
          field: "IsCertificated",
          title: "Certificação",
          cell: "certificationCell",
          filterable: false,
          sortable: false,
        },
      ];

      cols.push({
        cell: EditCommandCell,
        filterable: false,
        sortable: false,
        width: 75,
        title: "",
      });

      cols.push({
        cell: DeleteCommandCell,
        filterable: false,
        sortable: false,
        width: 75,
        title: "",
      });

      if (this.course != null || this.courseClass != null) {
        cols.unshift({
          field: "User.Name",
          title: "Aluno",
          cell: "userCell",
          sortable: true,
          filterCell: StringFilterCell,
        });
        cols.unshift({
          field: "User.Code",
          title: "Código",
          cell: "userCell",
          width: 125,
          sortable: true,
          filterCell: StringFilterCell,
        });
      }

      if (this.user != null) {
        cols.unshift({
          field: "CourseClass.Name",
          title: "Ação de Formação",
          filterable: false,
        });
        cols.unshift({
          field: "Course.Name",
          title: "Formação",
          cell: "courseCell",
          filterCell: StringFilterCell,
        });
        cols.unshift({
          field: "Course.Code",
          title: "Código",
          cell: "courseCell",
          width: 125,
          filterCell: StringFilterCell,
        });
      }
      return cols;
    },
  },
  async created () {
    await this.loadItems();
    if (this.courseClass) {
      await this.LoadSessions();
    }
  },
  methods: {
    sortChange (ev) {
      this.grid.sort = ev.sort;
      this.grid.pagging.pageId = 1;
      this.loadItems();
    },
    onFileChange (file) {
      this.subscription.File = file;
      this.isLoading = false;
    },
    openEditInscriptionModal () {
      this.subscription = { isBatch: true };
      this.subscription.Minutes = this.courseClass.Minutes;
      this.subscription.DateEnd = this.courseClass.DateEnd;
    },
    reload () {
      this.grid.pagging.pageId = 1;
      this.loadItems();
    },
    onCancelAction (callback) {
      this.reset();
      callback(true);
    },
    reset () {
      this.subscriptionOriginal = null;
      this.subscription = null;
      this.userInscription.IdCourse = 0;
      this.SingleInstance = { Code: "" };
      this.MassInstance = { Locations: [], Functions: [], CostCenters: [] };
    },
    wasPresent (session) {
      if (this.subscription.CourseSessionPresences) {
        var item = this.subscription.CourseSessionPresences.find(
          (s) => s.CourseSession.IdCourseSession === session.IdCourseSession
        );
        return item && item.WasPresent;
      }
      return false;
    },
    getCertificationUrl (item) {
      return (
        API_URL +
        "api/users/" +
        item.User.IdUser +
        "/certification/" +
        item.Course.IdCourse
      );
    },
    getStatusColor (item) {
      if (!item.IsInitiated) {
        return "yellow";
      }
      if (item.IsActive) {
        return "blue";
      }
      if (item.IsApproved == null) {
        return "green";
      }
      if (item.CourseClassPresence != null && !item.CourseClassPresence) {
        return "orange";
      }
      if (!item.IsApproved) {
        return "red";
      }

      if (item.Course.TypeId == 2 && item.Classification >= 0 && !item.HasTrainerAvaliation) {
        return "black";
      }

      return "green";
    },
    getStatusName (item) {

      if (!item.IsInitiated) {
        return "Não iniciado";
      }
      if (item.IsActive) {
        return "Em curso";
      }
      if (item.IsApproved == null) {
        return "Aprovado";
      }
      if (!item.IsApproved) {
        return "Não aprovado";
      }
      if (item.CourseClassPresence != null && !item.CourseClassPresence) {
        return "Não compareceu";
      }

      if (item.Course.TypeId == 2 && item.Classification >= 0 && !item.HasTrainerAvaliation) {
        return "A aguardar nota";
      }

      return "Aprovado";
    },
    pageChange (ev) {
      this.grid.pagging.pageId = ev.page.skip / ev.page.take + 1;
      this.loadItems();
    },
    async loadItems () {
      const options = GridHelper.ConvertOptionsFromNativeKendoUIGrid(
        this.grid.pagging,
        this.grid.filter,
        this.grid.sort
      );

      if (this.course) {
        options.Filters.push({
          Field: "IdCourse",
          Operator: "eq",
          Value: this.course.IdCourse,
        });
      }

      if (this.courseClass) {
        options.Filters.push({
          Field: "IdCourseClass",
          Operator: "eq",
          Value: this.courseClass.IdCourseClass,
        });
      }

      if (this.user) {
        options.Filters.push({
          Field: "IdUser",
          Operator: "eq",
          Value: this.user.IdUser,
        });
      }

      await subscriptionsService.Get(options).then((res) => {
        this.Items = res.data.Items;
        this.grid.pagging.total = res.data.Total;
      });
    },
    edit (item) {
      this.subscriptionOriginal = item.dataItem;
      this.subscription = { ...item.dataItem };
    },
    filterChange (ev) {
      this.grid.pagging.pageId = 1;
      this.grid.filter = ev.filter;
      this.loadItems();
    },
    remove (item) {
      var id = item.dataItem.IdUserCourseSubscription;
      kendo.confirm("Tem a certeza que quer apagar a inscrição?").done(() => {
        subscriptionsService
          .Delete(id)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Inscrição apagada com sucesso!"
            );
            this.loadItems();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          });
      });
    },
    async AddUserSubscription (callBack) {
      await subscriptionsService
        .Create({
          IdUser: this.user.IdUser,
          IdCourse: this.userInscription.IdCourse,
        })
        .then(() => {
          notificationServiceBus.showSuccessMessage(
            "Successo!",
            "Adicionou a inscrição com sucesso com sucesso!"
          );
          this.reset();
          this.loadItems();
          callBack(true);
        })
        .catch((error) => {
          callBack(false);
          notificationServiceBus.showError(error.response);
        });
    },
    async LoadSessions () {
      await courseSessionsService
        .Get({
          Filters: [
            {
              Field: "IdCourseClass",
              Operator: "eq",
              Value: this.courseClass.IdCourseClass,
            },
          ],
        })
        .then((res) => {
          this.courseClassSessions = res.data.Items;
        });
    },
    async AddSingle (callBack) {
      await subscriptionsService
        .CreateByCodes(
          this.SingleInstance.Code.split("\n"),
          this.courseClass != null
            ? this.courseClass.IdCourse
            : this.course.IdCourse,
          this.courseClass != null ? this.courseClass.IdCourseClass : null
        )
        .then((res) => {
          notificationServiceBus.showInfoMessage(
            "Resultado importação em massa",
            `<p><strong>Novas inscrições</strong> ${res.data.UsersInserted.length}</p>
            <p><strong>Incrições ativas</strong> ${res.data.UsersIgnored.length}</p>`
          );
          this.reset();
          this.loadItems();
          callBack(true);
        })
        .catch((error) => {
          callBack(false);
          notificationServiceBus.showError(error.response);
        });
    },
    async saveSubscription () {
      if (this.isLoading) return;
      this.isLoading = true;
      this.subscription.DateStart = this.subscription.DateEnd;
      if (this.subscription.isBatch) {
        await subscriptionsService
          .UpdateByCourseClass(
            this.courseClass.IdCourseClass,
            this.subscription
          )
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Alterou as inscrições da Ação de Formação com sucesso!"
            );
            this.loadItems();
            this.reset();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          })
          .finally(() => (this.isLoading = false));
      } else {
        await subscriptionsService
          .Update(this.subscription)
          .then(() => {
            notificationServiceBus.showSuccessMessage(
              "Successo!",
              "Alterou a inscrição com sucesso!"
            );
            this.loadItems();
            this.reset();
          })
          .catch((error) => {
            notificationServiceBus.showError(error.response);
          })
          .finally(() => (this.isLoading = false));
      }
    },
  },
};
</script>
