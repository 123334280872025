<template>
  <app-form-modal :title-dialog="'Adicionar Inscrições em massa'" :titleButton="'Adicionar em Massa'" :confirmAction="confirm" :formValid="isValid">
    <p class="mb-2">Escolha pelo menos uma das opções</p>
    <div class="dropdown-group">
      <app-function-selector v-model="idJobFunction"></app-function-selector>
    </div>
    <div class="dropdown-group">
      <app-location-selector v-model="idJobLocation"></app-location-selector>
    </div>
    <div class="dropdown-group">
      <app-department-selector v-model="idJobDepartment"></app-department-selector>
    </div>
    <div class="dropdown-group">
      <app-user-group-selector v-model="idUserGroup"></app-user-group-selector>
    </div>
    <v-select v-if="sessions" item-text="Name" :items="sessions" v-model="idCourseSession" item-value="IdCourseSession" :clearable="false" label="Sessão"></v-select>
  </app-form-modal>
</template>

<script>
import { notificationServiceBus } from "@/services/bus/notificationServiceBus";
import LocationSelectorComponent from "@/components/selectors/job-locations-dropdown";
import FunctionSelectorComponent from "@/components/selectors/job-functions-dropdown";
// import DepartmentSelectorComponent from "@/components/selectors/job-departments";
import DepartmentSelectorComponent from "@/components/selectors/job-departments-dropdown";
import UserGroupSelectorComponent from "@/components/selectors/user-groups";
import FormModalComponent from "@/components/modals/form-modal";
import userCourseSubscriptionsService from "@/services/api/userCourseSubscriptionsService";

export default {
  components: {
    "app-function-selector": FunctionSelectorComponent,
    "app-location-selector": LocationSelectorComponent,
    // "app-department-selector": DepartmentSelectorComponent,
    "app-department-selector": DepartmentSelectorComponent,
    "app-user-group-selector": UserGroupSelectorComponent,
    "app-form-modal": FormModalComponent,
  },
  props: ["course", "courseClass", "sessions"],
  data () {
    return {
      idJobFunction: 0,
      idJobCostcenter: 0,
      idJobLocation: 0,
      idJobDepartment: 0,
      idUserGroup: 0,
      idCourseSession: null,
    };
  },
  computed: {
    isValid () {
      if (
        this.idJobFunction > 0 ||
        this.idJobCostcenter > 0 ||
        this.idJobLocation > 0 ||
        this.idJobDepartment > 0 ||
        this.idUserGroup > 0
      ) {
        if (this.sessions && !this.idCourseSession) {
          return false;
        }
        return true;
      }
      return false;
    },
  },
  methods: {
    reset () {
      this.idJobCostcenter = 0;
      this.idJobFunction = 0;
      this.idJobLocation = 0;
      this.idJobDepartment = 0;
      this.idUserGroup = 0;
      this.idCourseSession = null;
    },
    onCostCenterSelected (id) {
      this.idJobCostcenter = id;
    },
    onFunctionSelected (id) {
      this.idJobFunction = id;
    },
    onLocationSelected (id) {
      this.idJobLocation = id;
    },
    onDepartmentSelected (id) {
      this.idJobDepartment = id;
    },
    onUserGroupSelected (id) {
      this.idUserGroup = id;
    },
    async confirm (callback) {
      await userCourseSubscriptionsService
        .CreateByBatch(
          this.courseClass ? this.courseClass.IdCourse : this.course.IdCourse,
          this.courseClass ? this.courseClass.IdCourseClass : null,
          this.idCourseSession,
          this.idJobLocation <= 0 ? null : this.idJobLocation,
          this.idJobCostcenter <= 0 ? null : this.idJobCostcenter,
          this.idJobFunction <= 0 ? null : this.idJobFunction,
          this.idJobDepartment <= 0 ? null : this.idJobDepartment,
          this.idUserGroup <= 0 ? null : this.idUserGroup
        )
        .then((res) => {
          notificationServiceBus.showInfoMessage(
            "Resultado importação em massa",
            `<p><strong>Novas inscrições</strong> ${res.data.UsersInserted.length}</p>
           <p><strong>Incrições ativas</strong> ${res.data.UsersIgnored.length}</p>`
          );
          this.reset();
          this.$emit("inscriptionsAdded");
        })
        .catch((error) => {
          notificationServiceBus.showError(error.response);
        })
        .finally(() => callback(true));
    },
  },
  created () { },
};
</script>

<style lang="scss">
@import "../../styles/variables.scss";

.dropdown-group .k-dropdown {
  width: 100% !important;
}

.dropdown-group {
  margin-bottom: 10px;
}
</style>
