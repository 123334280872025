<template>
  <div>
    <kendo-upload
      :multiple="false"
      name="file"
      @error="onError"
      @clear="onError"
      @cancel="onError"
      @progress="onProgress"
      @success="onSuccess"
      @select="onSuccess"
      @upload="beforeUpload"
      :async="options"
      :validation="validation"
    ></kendo-upload>
    <div class="mt-3" v-if="validations">
      Só pode carregar ficheiros com formato(s)
      <ul>
        <li v-for="v in validations" :key="v">
          <strong>{{ v }}</strong>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import AuthToken from "@/services/api/authTokenService";
import UploadHelper from "@/utilities/upload";
import UrlBuider from "@/utilities/url-builder";

export default {
  props: {
    async: {
      type: Boolean,
      required: true,
      default: false,
    },
    type: {
      type: String,
      required: true,
      default: "file",
    },
    validations: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      validation: {
        allowedExtensions: this.validations,
      },
      options: this.async
        ? {
            autoUpload: true,
            withCredentials: true,
            chunkSize: 2500000,
            saveUrl: UrlBuider.GetPath(`/api/upload/${this.type}`),
          }
        : {
            autoUpload: false,
          },
    };
  },
  methods: {
    onError(err) {
      this.$emit("onError", err);
    },
    onProgress() {
      this.$emit("onProgress", true);
    },
    onSuccess(evt) {
      if (this.async) {
        this.$emit("onSuccess", {
          Name: evt.files[0].name,
          Length: 0,
          Base64Data: "@",
        });
      } else {
        UploadHelper.LoadFile(evt)
          .then((file) => this.$emit("onSuccess", file))
          .catch(() => this.$emit("onSuccess", null));
      }
    },
    beforeUpload(e) {
      var xhr = e.XMLHttpRequest;
      if (xhr) {
        xhr.addEventListener("readystatechange", function () {
          if (xhr.readyState == 1) {
            xhr.setRequestHeader("Authorization", AuthToken);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss"></style>
