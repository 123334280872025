<template>
  <kendo-dropdownlist v-model="current" :data-source="entries" :data-text-field="'Name'" :data-value-field="'IdJobDepartment'" :optionLabel="label" @select="onChanged" />
</template>
<script>
import apiService from "@/services/api/jobDepartmentsService";

export default {
  props: ["value"],

  watch: {
    value () {
      this.current = this.value;
    },
  },
  data () {
    return {
      entries: [],
      current: this.value,
      label: {
        IdJobDepartment: null,
        Name: "Departamento...",
      },
    };
  },
  methods: {
    onChanged (e) {
      this.$emit("input", e.dataItem.IdJobDepartment);
    },
  },
  async created () {
    await apiService
      .Get()
      .then((res) => {
        this.entries = res.data.Items;
      })
      .catch(() => {
        this.entries = [];
      });
  },
};
</script>
