<template>
  <kendo-dropdownlist v-model="current" :data-source="entries" :data-text-field="'Name'" :data-value-field="'IdJobLocation'" :optionLabel="label" @select="onChanged" />
</template>
<script>
import apiService from "@/services/api/jobLocationsService";

export default {
  props: ["value"],

  watch: {
    value () {
      this.current = this.value;
    },
  },
  data () {
    return {
      entries: [],
      current: this.value,
      label: {
        IdJobLocation: null,
        Name: "Localização...",
      },
    };
  },
  methods: {
    onChanged (e) {
      this.$emit("input", e.dataItem.IdJobLocation);
    },
  },
  async created () {
    await apiService
      .Get()
      .then((res) => {
        this.entries = res.data.Items;
      })
      .catch(() => {
        this.entries = [];
      });
  },
};
</script>
