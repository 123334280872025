<template>
  <kendo-dropdownlist v-model="current" :data-source="Items" :data-text-field="'Name'" :data-value-field="'IdUserGroup'" :optionLabel="label" :style="{ width: '300px' }" />
</template>
<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import * as types from "@/store/types";

export default {
  props: ["value"],

  watch: {
    value () {
      this.current = this.value;
    },
  },
  data () {
    return {
      current: this.value,
      label: {
        IdUserGroup: null,
        Name: "Grupo de utilizadores...",
      },
    };
  },
  computed: {
    ...mapGetters({
      Items: types.GET_USER_GROUPS,
    }),
  },
  methods: {
    onChanged (e) {
      this.$emit("input", e.dataItem.IdUserGroup);
    },
  },
};
</script>
